import React, { useEffect, useState } from 'react';
import styles from './PersonCard.module.scss';
import { PersonCardUserField as userField } from './PersonCard.interface';
import { observer } from 'mobx-react-lite';
import autorizeState from '@store/accoutState/autorizeState';
import { UserDataFix } from '@store/accoutState/interfaces';
import Avatar from '@assets/Icons/Account/Avatar.png';

const getUserFields = ({ firstName, email, phone, lastName, group }: UserDataFix) => ([
	{title: 'ИМЯ ', value: firstName},
	{title: 'ФАМИЛИЯ ', value: lastName},
	{title: 'ГРУППА ', value: group?.name},
	{title: 'ПОЧТА', value: email},
	{title: 'НОМЕР ТЕЛЕФОНА', value: phone},
]);

const AccountCards: React.FC = observer(() => {
	const { user } = autorizeState;
	const information = getUserFields(user);

	const [avatar, setAvatar] = useState<string>('');

	useEffect(() => {
		const imageUser = user.image ? user.image : Avatar;
		setAvatar(imageUser);
	}, [user]);

	const createUserField = ({ title, value }: userField) => {
		const { infoPerson, titleStyle, valueStyle } = styles;

		if (!value) return;
		return (
			<div key={`Person-${title}`} className={infoPerson}>
				<div className={titleStyle}>{title}</div>
				<div className={valueStyle}>{value}</div>
			</div>
		);
	};

	return (
		<div className={styles.box}>
			<div>{information.map(createUserField)}</div>
			<img className={styles.imgStyle} src={avatar} alt='avatar'/>
		</div>
	);
});

export default AccountCards;
