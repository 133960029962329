import { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './ListMenu.module.scss';
import canvasState from '../../../../store/canvasState/canvasState';
import translationState from '../../../../store/translationState/translationState';
import { Box, List, ListItemButton, Typography } from '@mui/material';
import DeleteIcon from '../../../../assets/Icons/Translation/DeleteIcon';
import Polygons from '../../Canvas/Polygons';
import autorizeState from '../../../../store/accoutState/autorizeState';
import Polygon from '@components/Translation/Canvas/Polygon';

interface Props {
	polygons: Polygon[];	
	listRef: any;
}

const ListMenu: FC<Props> = observer(({ polygons, listRef }) => {
	const { currentPolygonNum, canvas, setCurrentPolygonNum, setPolygonSelect } = canvasState;
	const { setOpenModal, setContentType } = translationState;
	const { userRole } = autorizeState;

	const selectRef = useRef(null);
	// const textRef = useRef(null);

	useEffect(() => {
		if (!selectRef.current) return;

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}, [currentPolygonNum]);

	// useEffect(() => {
	// 	if(currentPolygonNum === -1) return;

	// 	if (textRef.current[currentPolygonNum]) {
	// 		textRef.current[currentPolygonNum].click();
	// 	}
	// }, [currentPolygonNum]);

	const handleSelectPolygon = (event: any, polId = null) => {
		const index = polygons.findIndex(({ id }) => id === polId);

		setCurrentPolygonNum(index);
		setPolygonSelect(true);

		const areas = new Polygons(canvas);
		areas.setCurPolygon(index);
		areas.polygonSelection();

		selectRef.current = event.target;
	};

	const openModalDelete = () => {
		setOpenModal(true);

		setContentType('DeleteArea');
	};

	return (
		<>
			<Box className={styles.titleBox}>
				<span className={styles.title}>Отслеживаемые зоны</span>
			</Box>

			<List className={styles.list} disablePadding ref={listRef}>
				{polygons.map((polygon, index) => {
					const isActive = currentPolygonNum === index;
					const name = polygon.getName();

					const inlineListItem = {
						height: 45,

						display: 'flex',
						justifyContent: 'space-between',

						padding: '8px 16px',
					};

					const { recName } = styles;
					const color = isActive ? '#00c2fd' : 'var(--colorText)';

					const { settingsItem } = styles;

					return (
						<ListItemButton
							key={polygon.id}
							style={inlineListItem}
							className={settingsItem}
							onClick={(event) => handleSelectPolygon(event, polygon.id)}
							// ref={(element) => {
							// 	if(currentPolygonNum === -1) return;

							// 	textRef.current[currentPolygonNum] = element;
							// }}
						>
							<Typography className={recName} style={{ color }}>
								{name}
							</Typography>

							{userRole !== 'spotoperator' && (
								<div className={styles.iconSet} onClick={openModalDelete}>
									<DeleteIcon />
								</div>
							)}
						</ListItemButton>
					);
				})}
			</List>
		</>
	);
});

export default ListMenu;
