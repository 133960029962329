import React, {useState} from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import styles from './PdfFile.module.scss';
import {PdfFileProps} from './PdfFile.interface';
import PdfButtons from './PdfButtons/PdfButtons';
import CloseIcon from '@assets/Icons/Map/CloseIcon';
import MainButton from '@helpers/components/MainButton/MainButton';
import connectState from '@store/connectState/connectState';
import { observer } from 'mobx-react-lite';

const PdfFile: React.FC<PdfFileProps> = observer(({handleShowPdf}) => {
	const { linkManual, linkDeviceManual } = connectState;

	const [numPages, setNumPages] = useState<number | null>(null);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pdfFileIndex, setPdfFileIndex] = useState(0);

	const height = window.innerHeight;

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
	};
	const handleChangePage = (n: number | null = null) => {
		setCurrentPage((prev) => prev + (n || 0));
	};

	const pdfFiles = [linkManual, linkDeviceManual];

	const handleChangePdf = (index: number) => {
		setPdfFileIndex(index);
		setCurrentPage(1);
	};

	const handleDownloadPdf = async () => {
		try {
			const selectedFile = pdfFiles[pdfFileIndex];
			const response = await fetch(selectedFile);
			const blob = await response.blob();
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'manual.pdf');
			document.body.appendChild(link);
			link.click();
			link.parentNode?.removeChild(link);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className={styles.page}>
			<MainButton className={styles.closeIcon} onClick={() => handleShowPdf()}>
				<CloseIcon />
			</MainButton>

			<Document file={pdfFiles[pdfFileIndex]} onLoadSuccess={onDocumentLoadSuccess}>
				<Page pageNumber={currentPage} height={height - 90} inputProps={{ preventDefault: true }} />
			</Document>

			{!!numPages &&
				<div className={styles.buttonsContainer}>
					<PdfButtons
						handleChangePdf={handleChangePdf}
						currentPage={currentPage}
						handleChangePage={handleChangePage}
						lastPage={numPages || 0}
						index={pdfFileIndex}
						handleDownloadPdf={handleDownloadPdf}
					/>
				</div>
			}
		</div>
	);
});

export default PdfFile;
