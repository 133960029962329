import { ResultEventData } from '../../store/eventState/interface';

const temperGraph = {
	name: 'Average Temperature',
	value: 0,
};

const airTemp = {
	name: 'Air Temperature',
	value: 0,
};

const viewData = {
	temperGrid: [],
	polygons: [],
	temperGraph,
	airTemp,
};

const sound = {
	track: '',
	corr: 0,
	energy: 0,
};

const defaultEvent: ResultEventData = {
	id: null,
	id_message: null,
	idCamera: '',
	cameraName: '',
	idPreset: null,
	messageType: '',
	presetName: '',
	date: '',
	time: '',
	name: '',
	soundEnergy: 0,
	soundCorrelation: 0,
	soundFrequency: 0,
	spot: null,
	img: '',
	temperImage: '',
	protocolImage: '',
	viewData,
	sound,
	t_max: '0 °C',
	t_mean: '0 °C',
	t_min: '0 °C',
	zone: null,
	zoneName: '',
};

export const filterOperator: { [key: string]: string } = {
	equals: '',
	is: '',
	contains: '__contains',
	after: '__gt',
	before: '__lt',
	onOrAfter: '__gte',
	onOrBefore: '__lte',
};

export const getDefEvent = () => JSON.parse(JSON.stringify(defaultEvent));

export const patrolTypes: { [key: string]: string }  = {
	'true-true': 'both',
	'true-false': 'schedule',
	'false-true': 'every',
	'false-false': 'off',
};

export const typesArea = [
	{ id: 1, value: 'IR', label: 'Контроль IR' },
	{ id: 2, value: 'mov_con', label: 'Подвижный контакт' },
	{ id: 3, value: 'fix_con', label: 'Неподвижный контакт' },
	{ id: 4, value: 'iso', label: 'Изолятор' },
	{ id: 5, value: 'entry', label: 'Ввод' },
	{ id: 6, value: 'corp', label: 'Корпус' },
	{ id: 7, value: 'elec_line', label: 'Токопровод' },
];
