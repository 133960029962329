import { useEffect, useRef, useState } from 'react';
import styles from './PlacesMenu.module.scss';
import { PlaceMenuProps as Props } from './PlacesMenu.interface';
import { ClickAwayListener } from '@mui/material';
import SearchIcon from '../../../assets/Icons/Map/SearchIcon';
import CloseIcon from '../../../assets/Icons/Map/CloseIcon';
import ListItems from './ListItems/ListItems';
import SwitchIcon from '../../../assets/Icons/ControlPage/SwitchIcon';
import placeState from '../../../store/placeState/placeState';
import devicesState from '../../../store/devicesState/devicesState';
import { PlaceDevices as Place } from '../../../store/placeState/interfaces';
import { observer } from 'mobx-react-lite';
import { useQueryClient } from 'react-query';

const PlacesMenu: React.FC<Props> = observer(({ isDashboardPage, isMapPage, isDeviceMap }) => {
	const { places, selectPlaceId, setSelectedPlaces, setSelectPlaceId } = placeState;
	const { devices, selectDeviceId } = devicesState;

	const queryClient = useQueryClient();

	const filterTimerRef = useRef<NodeJS.Timeout | null>(null);

	const [isVisibleList, setIsVisibleList] = useState(false);
	const [isClickSearch, setIsClickSearch] = useState(false);
	const [isClickTitle, setIsClickTitle] = useState(false);
	const [valueInput, setValueInput] = useState('');
	const [titleMenu, setTitleMenu] = useState('');
	const [isVisibleDevices, setIsVisibleDevices] = useState(false);

	const [filteredList, setFilteredList] = useState(places);

	const menuStyle = `${styles.searchField} ${isVisibleList && styles.open} ${(isMapPage || isDeviceMap) && styles.mapContainer}`;

	useEffect(() => {
		if (isDashboardPage) setIsVisibleDevices(false);
	}, []);

	useEffect(() => {
		if(!isClickSearch) setValueInput('');
	}, [isClickSearch]);

	useEffect(() => {
		filteredItems();
	}, [valueInput, selectPlaceId]);

	useEffect(() => {		
		setTitleMenu(getTitle());
	}, [selectPlaceId, selectDeviceId, devices]);

	const getTitle = () => {
		const namePlace = places.find(({ isSelected }) => isSelected)?.name;
		const nameDevice = devices.find(({ isSelected }) => isSelected)?.name;

		if(!namePlace) return 'Места не найдены';

		if(!nameDevice || isDashboardPage) return `${namePlace}`;

		return `${namePlace} - ${nameDevice}`;
	};

	const filteredItems = () => {
		if (filterTimerRef.current) {
			clearTimeout(filterTimerRef.current);
		}

		filterTimerRef.current = setTimeout(() => {
			const filtered = places.filter(({ name }) => name.toLowerCase().includes(valueInput.toLowerCase()));

			setFilteredList(filtered);
		}, 500);
	};

	const selectPlaceList = (id: Place['id']) => {
		setSelectPlaceId(id);
		setSelectedPlaces(id, true);
		setValueInput('');
		queryClient.invalidateQueries('getDevices');
		queryClient.invalidateQueries('getPresets');
	};

	const handleClickAwayEvent = () => {
		setIsVisibleList(false);
		setIsClickSearch(false);
		setIsClickTitle(false);
	};

	const handleSearchVisible = () => {
		setIsClickTitle((prev) => !prev);
		setIsClickSearch((prev) => !prev);
	};

	const closeTitle = () => {
		setIsVisibleList(false);
		setIsClickTitle(false);
	};

	const handleShowSearch = () => {
		setIsVisibleList((prevIsShow) => !prevIsShow);
		setIsClickSearch(true);
	};

	const handleShowTitle = () => {
		setIsVisibleList((prevIsShow) => !prevIsShow);
		setIsClickTitle(true);
	};

	const handleSwitchReturnPlaces = () => {
		setValueInput('');
		setIsVisibleDevices(false);
	};

	return (
		<ClickAwayListener onClickAway={handleClickAwayEvent}>
			<div className={menuStyle}>
				<div className={`${styles.menuSearch} ${(isMapPage || isDeviceMap) && styles.mapContainer}`}>
					{isVisibleList ? (
						<>
							{isClickSearch && (
								<div className={styles.titleSearch}>
									<div className={styles.icon} onClick={handleSearchVisible}>
										<SearchIcon />
									</div>
									<div className={styles.search}>
										<input
											className={styles.searchInput}
											value={valueInput}
											onChange={(e) => setValueInput(e.target.value)}
										/>

										<div className={styles.closeIcon} onClick={() => setValueInput('')}>
											<CloseIcon />
										</div>
									</div>
								</div>
							)}
							{isClickTitle && (
								<div className={styles.title}>
									<div className={styles.icon} onClick={handleSearchVisible}>
										<SearchIcon />
									</div>
									<div className={styles.selectName} onClick={closeTitle}>
										{titleMenu}
									</div>

									{isVisibleDevices && (
										<div className={styles.icon} onClick={handleSwitchReturnPlaces}>
											<SwitchIcon />
										</div>
									)}
								</div>
							)}
						</>
					) : (
						<div className={styles.title}>
							<div className={styles.icon} onClick={handleShowSearch}>
								<SearchIcon />
							</div>
							<div className={styles.selectName} onClick={handleShowTitle}>
								{titleMenu}
							</div>
						</div>
					)}
				</div>

				<ListItems
					valueSearch={valueInput}
					isVisibleList={isVisibleList}
					isDashboardPage={isDashboardPage}
					isMapPage={isMapPage}
					isDeviceMap={isDeviceMap}
					arrayItems={filteredList}
					isVisibleDevices={isVisibleDevices}
					setVisibleDevices={setIsVisibleDevices}
					clickItem={selectPlaceList}
				/>
			</div>
		</ClickAwayListener>
	);
});

export default PlacesMenu;
