import styles from './AnaliticaPage.module.scss';
import { AnaliticaPageProps, WebSocketCommands } from '../Pages.interface';
import HelmetTags from '@helpers/components/HelmetTags/HelmetTags';
import MainButton from '@helpers/components/MainButton/MainButton';
import BackIcon from '../../assets/Icons/LeftMenu/BackIcon';
import { useNavigate } from 'react-router-dom';
import Analitica from '../../components/Analitica/Analitica';
import FieldSearchPlaces from '../../components/FieldSearchPlaces/FieldSearchPlaces';
import connectState from '@store/connectState/connectState';
import {useEffect, useRef, useState} from 'react';
import placeState from '@store/placeState/placeState';
import devicesState from '@store/devicesState/devicesState';
import { apiGet } from '@api/api';
import { checkPageReady } from '@helpers/functions/loading/analiticaLoading';
import { CheckLoading } from '@components/CheckLoading/CheckLoading';
import {observer} from 'mobx-react-lite';
import {
	PreparePreset,
	prepareHeatEvents,
	prepareIREvents,
	preparePresetsData,
} from '@api/AnaliticaPage/prepareAnaliticaData';
import analiticaState from '@store/analiticaState/analiticaState';
import presetState from '@store/presetState/presetState';
import {HeatResults, IRResults} from '@api/AnaliticaPage/interface';
import {HeatAnalitica, IRAnalitica, PeriodTypeKeys} from '@store/analiticaState/interface';
import autorizeState from '@store/accoutState/autorizeState';
import { callPresetCommand, getActivePreset } from '@components/Translation/wsWorking/wsCommands';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AnaliticaPage: React.FC<AnaliticaPageProps> = observer(({ metaTags }) => {
	const navigate = useNavigate();
	const { aiEventLink, getPresetsAnaliticaLink } = connectState;
	const { selectPlaceId } = placeState;
	const { selectDeviceId } = devicesState;
	const { setHeatAnalitica, setIRAnalitica } = analiticaState;
	const { selectPresetNumWs, selectPresetId, setPresets, setSelectPresetId, setCurrentNumPresetWs } = presetState;
	const { checkAuth } = autorizeState;

	const [countRows, setCountRows] = useState<number>(0);
	const [pageCounter, setPageCounter] = useState<number>(0);
	const [prevLink, setPrevLink] = useState<string>('');
	const [nextLink, setNextLink] = useState<string>('');
	const [countRowsIR, setCountRowsIR] = useState<number>(0);
	const [pageCounterIR, setPageCounterIR] = useState<number>(0);
	const [prevLinkIR, setPrevLinkIR] = useState<string>('');
	const [nextLinkIR, setNextLinkIR] = useState<string>('');
	const [selectedPeriod, setSelectedPeriod] = useState<keyof PeriodTypeKeys>('d');
	const [selectedPeriodIR, setSelectedPeriodIR] = useState<keyof PeriodTypeKeys>('d');

	const socket = useRef<WebSocket | null>(null);
	const [isReconnect, setIsReconnect] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			checkPageReady('isPlaces', true);
			checkPageReady('isDevices', true);
		}, 300);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		if (!selectPresetNumWs) return;
		callPresetCommand(selectPresetNumWs, socket.current);
	}, [selectPresetNumWs]);

	useEffect(() => {
		if (!socket.current) return;

		const intervalPool = setInterval(() => {
			getActivePreset(socket.current);
		}, 60000);

		return () => clearInterval(intervalPool);
	}, [socket.current]);

	useEffect(() => {
		let newSocket: WebSocket | null = null;

		const intervalId = setInterval(() => {
			try {
				const { wsLink } = connectState;
				const { token } = autorizeState;

				if (!selectDeviceId) return;
				const linkConnect = `${wsLink}${selectDeviceId}/?token=${token}&page=camerapage`;

				newSocket = new WebSocket(linkConnect);
				newSocket.onopen = () => getActivePreset(socket.current);
				newSocket.onmessage = (e) => socketMessage(e);
				newSocket.onclose = (e) => socketClose(e);

				socket.current = newSocket;

				clearInterval(intervalId);
			} catch (error) {
				console.error(error);
			}
		}, 1500);

		return () => {
			try {
				if (newSocket instanceof WebSocket) newSocket.close();
				clearInterval(intervalId);
			} catch (error) {
				console.error(error);
			}
		};
	}, [selectDeviceId, isReconnect]);

	const socketClose = (event: CloseEvent) => {
		const closeConnectMsg = 'Соединение закрыто чисто';
		if (event.wasClean) console.log(closeConnectMsg);
		else {
			setIsReconnect((prevIsBreak) => !prevIsBreak);
		}
	};

	const socketMessage = (event: MessageEvent) => {
		try {
			const { command, data, success } = JSON.parse(event.data);
			console.log(JSON.parse(event.data));

			const actions: WebSocketCommands = {
				CAMERASTATUS: () => console.log(data.status),
				//Пресеты
				GETACTIVEPRESET: () => setCurrentNumPresetWs(data),
				CALLPRESET: () => {
					if (success) {
						getActivePreset(socket.current);
					} else {
						errorCommand();
					}
				},
			};

			actions[command]();
		} catch (error) {
			console.log('Не получилось прочитать ответ');
			console.error(error);
		}
	};

	useEffect(() => {
		const controller = new AbortController();
		if(!selectPresetId || !aiEventLink || !selectDeviceId || !selectPlaceId) return;

		const url = `${aiEventLink}${selectDeviceId}/getaievents/?id_psw=${selectDeviceId}&preset=${selectPresetId}&analytic_type=ir&period_type=${selectedPeriodIR}`;
		getIREvents(url, controller);

		return () => controller.abort();
	}, [aiEventLink,  selectedPeriodIR, selectPresetId, selectPlaceId]);

	useEffect(() => {
		const controller = new AbortController();

		if( !getPresetsAnaliticaLink || !selectDeviceId ) return;
		const url = `${getPresetsAnaliticaLink}${selectDeviceId}/getshortpresetsforaipage/`;
		getPresets(url, controller);

		return () => controller.abort();
	}, [getPresetsAnaliticaLink, selectDeviceId]);

	useEffect(() => {
		const controller = new AbortController();

		if (!selectPresetId || !aiEventLink || !selectDeviceId) return;

		const url = `${aiEventLink}${selectDeviceId}/getaievents/?id_psw=${selectDeviceId}&analytic_type=heat&preset=${selectPresetId}&period_type=${selectedPeriod}`;
		getHeatEvents(url, controller);

		return () => controller.abort();
	}, [aiEventLink, selectedPeriod, selectPresetId, selectPlaceId]);

	const getHeatEvents = async (url = '', controller = new AbortController()) => {
		if (!selectPresetId || !aiEventLink || !selectDeviceId) return;
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;

			setHeatAnalitica([] as HeatAnalitica[]);
			if (isError) return;
			const heatEvents = prepareHeatEvents(data as HeatResults);

			const { analitica, pagination} = heatEvents;

			setCountRows(pagination?.count);
			setPrevLink(pagination?.previous);
			setNextLink(pagination?.next);

			setHeatAnalitica(analitica);
		});
	};

	const getIREvents = async (url = '', controller = new AbortController()) => {
		if (!selectPresetId || !aiEventLink || !selectDeviceId || !selectPlaceId) return;

		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;

			setIRAnalitica([] as IRAnalitica[]);
			if (isError) return;
			const irEvents = prepareIREvents(data as IRResults);

			const { analitica, pagination} = irEvents;

			setCountRowsIR(pagination?.count);
			setPrevLinkIR(pagination?.previous);
			setNextLinkIR(pagination?.next);
			setIRAnalitica(analitica);
		});
	};

	const getPresets  = async (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}

			const presets = preparePresetsData(data as PreparePreset[]);

			if(!presets.length) {
				setSelectPresetId(null);
			}

			setPresets(presets);
		});
	};

	const errorCommand = () => {
		toast.error('Команда не прошла, попробуйте позже.', {
			autoClose: 2000,
			className: styles.toastMessage,
		});
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<CheckLoading page='analitica'>
			<ToastContainer />
			<div className={styles.main}>

				<HelmetTags metaTags={metaTags} />
				<MainButton className={styles.buttonPos} onClick={handleGoBack}>
					<BackIcon />
				</MainButton>

				<FieldSearchPlaces />

				<Analitica
					countRows={countRows}
					countRowsIR={countRowsIR}
					pageCounter={pageCounter}
					pageCounterIR={pageCounterIR}
					prevLink={prevLink}
					prevLinkIR={prevLinkIR}
					nextLink={nextLink}
					nextLinkIR={nextLinkIR}
					updateHeat={getHeatEvents}
					updateIR={getIREvents}
					setCountRows={setCountRows}
					setCountRowsIR={setCountRowsIR}
					setPageCounter={setPageCounter}
					setPageCounterIR={setPageCounterIR}
					setPrevLink={setPrevLink}
					setPrevLinkIR={setPrevLinkIR}
					setNextLink={setNextLink}
					setNextLinkIR={setNextLinkIR}
					setSelectedPeriodIR={setSelectedPeriodIR}
					setSelectedPeriod={setSelectedPeriod}
					selectePeriodIR={selectedPeriodIR}
					selectePeriod={selectedPeriod}
				/>
			</div>
		</CheckLoading>
	);
});

export default AnaliticaPage;
