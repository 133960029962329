import { useState, useEffect, useRef } from 'react';
import { ClickAwayListener } from '@mui/material';
import { observer } from 'mobx-react-lite';
import styles from './PresetsMenu.module.scss';
import PresetsList from './PresetsList/PresetsList';
import SearchIcon from '@assets/Icons/Map/SearchIcon';
import CloseIcon from '@assets/Icons/Map/CloseIcon';
import presetState from '@store/presetState/presetState';
import devicesState from '@store/devicesState/devicesState';
import { Preset } from '@store/presetState/interfaces';
import canvasState from '@store/canvasState/canvasState';

const PresetsMenu: React.FC = observer(() => {
	const { presets, selectPresetId, isPatrol, currentNumPresetWs, setIsPatrol, setSelectPresetId, setSelectPresetNumWs } = presetState;
	const { devices } = devicesState;
	const { setCurrentPolygonNum } = canvasState;

	const nameDevice = devices.find(({ isSelected }) => isSelected)?.name;

	const filterTimerRef = useRef<NodeJS.Timeout | null>(null);

	const [isVisibleList, setIsVisibleList] = useState(false);
	const [isClickSearch, setIsClickSearch] = useState(false);
	const [isClickTitle, setIsClickTitle] = useState(false);
	const [valueInput, setValueInput] = useState('');
	const [titleMenu, setTitleMenu] = useState('');

	const [filteredList, setFilteredList] = useState(presets);

	const menuStyle = `${styles.searchField} ${isVisibleList && styles.open}`;

	useEffect(() => {
		if (!selectPresetId) clickPresetItem(presets[0]?.id, presets[0]?.number);
	});

	useEffect(() => {
		const selectPresetName = presets.find(({ number }) => number === currentNumPresetWs)?.name;

		setTitleMenu(selectPresetName ?? '');
	}, [currentNumPresetWs, presets]);

	useEffect(() => {
		filteredPresets();
	}, [valueInput, selectPresetId, presets]);

	const filteredPresets = () => {
		if (filterTimerRef.current) {
			clearTimeout(filterTimerRef.current);
		}

		filterTimerRef.current = setTimeout(() => {
			const filtered = presets.filter(({ name }) => name.toLowerCase().includes(valueInput.toLowerCase()));

			setFilteredList(filtered);
		}, 500);
	};

	const clickPresetItem = (id: Preset['id'], num: Preset['number']) => {
		setSelectPresetId(id);
		setSelectPresetNumWs(num);
		setValueInput('');
		setCurrentPolygonNum(-1);
	};

	const handleClickAwayEvent = () => {
		setIsVisibleList(false);
		setIsClickSearch(false);
		setIsClickTitle(false);
	};

	const handleSearchVisible = () => {
		setIsClickTitle((prev) => !prev);
		setIsClickSearch((prev) => !prev);
	};

	const closeTitle = () => {
		setIsVisibleList(false);
		setIsClickTitle(false);
	};

	const handleShowSearch = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();

		if(!nameDevice) return;

		setIsVisibleList((prevIsShow) => !prevIsShow);
		setIsClickSearch(true);
	};

	const handleShowTitle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();

		if(!nameDevice) return;

		setIsVisibleList((prevIsShow) => !prevIsShow);
		setIsClickTitle(true);
	};

	return (
		<ClickAwayListener onClickAway={handleClickAwayEvent}>
			<div className={menuStyle}>
				<div className={styles.menuSearch}>
					{isVisibleList ? (
						<>
							{isClickSearch && (
								<div className={styles.titleSearch}>
									<div className={styles.icon} onClick={handleSearchVisible}>
										<SearchIcon />
									</div>
									<div className={styles.search}>
										<input
											className={styles.searchInput}
											value={valueInput}
											onChange={(e) => setValueInput(e.target.value)}
										/>

										<div className={styles.closeIcon} onClick={() => setValueInput('')}>
											<CloseIcon />
										</div>
									</div>
								</div>
							)}
							{isClickTitle && (
								<div className={styles.title}>
									<div className={styles.icon} onClick={handleSearchVisible}>
										<SearchIcon />
									</div>
									<div className={styles.selectName} onClick={closeTitle}>
										{titleMenu}
									</div>
								</div>
							)}
						</>
					) : (
						<div className={styles.title} onClick={handleShowTitle}>
							<div className={styles.icon} onClick={handleShowSearch}>
								<SearchIcon />
							</div>
							<div className={styles.selectName} onClick={handleShowTitle}>
								{nameDevice ? titleMenu : 'Устройство не выбрано'}
							</div>
						</div>
					)}
				</div>

				<PresetsList
					isVisibleList={isVisibleList}
					isSearchPreset={!isClickSearch}
					arrayItems={filteredList}
					clickPreset={clickPresetItem}
					setIsPatrol={setIsPatrol}
					isPatrol={isPatrol}
					closeMenu={handleClickAwayEvent}
				/>
			</div>
		</ClickAwayListener>
	);
});

export default PresetsMenu;
