import React, { FC, useState } from 'react';
import styles from '../ModalUpdatePresets.module.scss';
import { PropsDelete } from '../PresetsModals.interface';
import ConfirmButtons from '@helpers/components/ConfirmButtons/ConfirmButtons';
import presetState from '@store/presetState/presetState';

const DeleteModal: FC<PropsDelete> = ({ selectPreset, handleDiscardButton }) => {
	const { setIsDeletePreset, setDeletePreset } = presetState;

	const [isFocused, setIsFocused] = useState(false);
	const [isDisableButton, setDisableButton] = useState(true);

	const handleDeletePreset = () => {
		if (!selectPreset) return;

		setDeletePreset(selectPreset.number);
		setIsDeletePreset();
		handleDiscardButton();
	};

	const checkEmpty = (e: React.ChangeEvent<HTMLInputElement>) => {
		const isMatched = e.target.value === selectPreset?.name;

		setDisableButton(!isMatched);
	};

	return (
		<div className={styles.modalContainer}>
			<div className={styles.modalContent}>
				Удаление пресета
				<span style={{ color: '#00C2FD' }}> {selectPreset?.name}</span>
			</div>

			<div className={styles.search}>
				<input
					className={styles.searchInput}
					placeholder="Название пресета"
					onChange={checkEmpty}
					autoFocus={isFocused}
					onFocus={() => setIsFocused(true)}
				/>
			</div>

			<ConfirmButtons
				nameOk="Удалить"
				nameNo="Отмена"
				isDisabled={isDisableButton}
				handleOk={handleDeletePreset}
				handleCancel={handleDiscardButton}
			/>
		</div>
	);
};

export default DeleteModal;
