import { Device } from '@store/devicesState/interface';
import { PlaceDevices as Place } from '@store/placeState/interfaces';
import { LatLngTuple } from 'leaflet';
import placeState from '@store/placeState/placeState';
import devicesState from '@store/devicesState/devicesState';
import autorizeState from '@store/accoutState/autorizeState';
import { DataPlace, PrepareDevices } from './prepareMapData.interface';
import { apiGetQuery } from '@api/api';
import presetState from '@store/presetState/presetState';

export const getPlacesQuery = async (link: string) => {
	const { setPlaces } = placeState;
	const data = await apiGetQuery(link);

	const places = prepareMapPlaces(data as DataPlace[]);
	setPlaces(places);
};

export const prepareMapPlaces = (data: DataPlace[]): Place[] => {
	const { selectPlaceId, setSelectPlaceId } = placeState;

	const oldSelectedIndex = data.findIndex(({ id }) => id === selectPlaceId);
	const isOldSelectedPlace = oldSelectedIndex !== -1 ? oldSelectedIndex : 0;

	const places = data.map((place) => {
		const { id, lat, lng, name } = place;

		const coords: LatLngTuple = [lat, lng];

		return {
			id,
			coords,
			name,
			isSelected: false,
			isHaveEvents: false,
		};
	});

	if(!places.length) return [];

	places[isOldSelectedPlace].isSelected = true;
	setSelectPlaceId(places[isOldSelectedPlace].id);

	return places;
};

export const getDevicesQuery = async (link: string) => {
	const { setDevices, setselectDeviceId } = devicesState;
	const { setSelectPresetId, setPresets } = presetState;
	const data = await apiGetQuery(link);

	const devices = prepareMapDevices(data as PrepareDevices);
	if(!devices.length) {
		setSelectPresetId(null);
		setPresets([]);
		setselectDeviceId(null);
	}
	setDevices(devices);
};

export const prepareMapDevices = (data: PrepareDevices): Device[] => {
	const { selectDeviceId, setselectDeviceId } = devicesState;
	const { cameras, role, spot } = data;

	const oldSelectedIndex = cameras.findIndex(({ id_psw }) => id_psw === selectDeviceId);
	const isOldSelectedPlace = oldSelectedIndex !== -1 ? oldSelectedIndex : 0;

	const devices = cameras.map((device) => {
		const { id_psw, lat, lng, name, time_created } = device;

		const coords: LatLngTuple = [lat, lng];

		return {
			id: id_psw,
			coords,
			name,
			spot,
			timeCreated: time_created,
			isSelected: false,
		};
	});

	if(!devices.length) return [];

	devices[isOldSelectedPlace].isSelected = true;
	setselectDeviceId(devices[isOldSelectedPlace].id);

	autorizeState.setUserRole(role);

	return devices;
};
