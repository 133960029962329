import React from 'react';
import { useQuery } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import Redirect from '../helpers/components/Redirect/Redirect';
import MapPage from '../pages/MapPage/MapPage';
import AccountPage from '../pages/AccountPage/AccountPage';
import ControlPage from '../pages/ControlPage/ControlPage';
import LeftMenu from '../components/LeftMenu/LeftMenu';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import AccountMenu from '../components/AccountMenu/AccountMenu';
import MapDevicesPage from '../pages/MapDevicesPage/MapDevicesPage';
import TranslationPage from '../pages/TranslationPage/TranslationPage';
import AnaliticaPage from '../pages/AnaliticaPage/AnaliticaPage';
import connectState from '@store/connectState/connectState';
import placeState from '@store/placeState/placeState';
import { getDevicesQuery, getPlacesQuery } from '@api/MapPage/prepareMapData';
import {
	metaAccountPage,
	metaAnaliticaPage,
	metaControlPage,
	metaDashboardPage,
	metaMapDevicesPage,
	metaMapPage,
	metaTranslationPage,
} from './metaTags';

const UserAccessRouter: React.FC = () => {
	const path = '/';

	const { getPlacesLink, getDevicesLink } = connectState;
	const { selectPlaceId } = placeState;

	useQuery(['getPlaces', getPlacesLink], () => getPlacesQuery(getPlacesLink));

	useQuery(
		['getDevices', getDevicesLink, selectPlaceId],
		() => getDevicesQuery(`${getDevicesLink}${selectPlaceId}/getcameras/`),
		{
			enabled: !!selectPlaceId,
		}
	);

	return (
		<>
			<LeftMenu />
			<AccountMenu />
			<Routes>
				<Route path='/' element={<MapPage metaTags={metaMapPage} />} />
				<Route path='/device/:spot' element={<MapDevicesPage metaTags={metaMapDevicesPage} />} />
				<Route path='/control' element={<ControlPage metaTags={metaControlPage} />} />
				<Route path='/account' element={<AccountPage metaTags={metaAccountPage} />} />
				<Route path='/dashboard' element={<DashboardPage metaTags={metaDashboardPage} />} />
				<Route path='/translation' element={<TranslationPage metaTags={metaTranslationPage} />} />
				<Route path='/analitica' element={<AnaliticaPage metaTags={metaAnaliticaPage} />} />
				<Route path='*' element={<Redirect to={path} />} />
			</Routes>
		</>
	);
};

export default UserAccessRouter;
