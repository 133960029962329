import canvasState from '@store/canvasState/canvasState';
import { PolygonItem, PreparePolygon, Preset } from '@store/presetState/interfaces';
import presetState from '@store/presetState/presetState';
import { PrepareCamParams, PreparePreset, PrepareSettings, PresetSettings } from './prepareTranslationData.interface';
import { apiGetQuery } from '@api/api';
import { patrolTypes } from '@helpers/defaultsData/defaultsData';
import devicesState from '@store/devicesState/devicesState';

export const getPresetsQuery = async (link: string) => {
	const { setSelectPresetId, setPresets } = presetState;
	const data = await apiGetQuery(link);

	const presets = preparePresetsData(data as PreparePreset);
	if (!presets.length) setSelectPresetId(null);
	setPresets(presets);
};

export const preparePresetsData = (data: PreparePreset): Preset[] => {
	const { selectPresetId, setIsPatrol, setSelectPresetId } = presetState;
	const { patrol_type, presets } = data;

	if (!presets.length) {
		setSelectPresetId(null);
		return [];
	}

	setIsPatrol(patrol_type === 'off' ? false : true);

	const oldSelectedIndex = presets.findIndex(({ id }) => id === selectPresetId);
	const isOldSelectedPreset = oldSelectedIndex !== -1 ? oldSelectedIndex : 0;

	const resultPresets = presets.map((preset) => {
		const { id, name, preset_number, preset_min_temp, preset_max_temp } = preset;

		return {
			id,
			name,
			number: preset_number,
			t_min: preset_min_temp,
			t_max: preset_max_temp,
		};
	});

	setSelectPresetId(resultPresets[isOldSelectedPreset].id);

	return resultPresets;
};

export const getPolygonsQuery = async (link: string) => {
	const { selectDeviceId } = devicesState;
	const { selectPresetId, isEditPolygonsArr, setIsEditPolygonsArr } = presetState;
	const { setRawData } = canvasState;

	const data = await apiGetQuery(link);

	const polygons = preparePolygonsData(data as PreparePolygon[]);
	setIsEditPolygonsArr(!isEditPolygonsArr);
	
	setRawData(selectDeviceId, selectPresetId, polygons);
};

export const preparePolygonsData = (data: PreparePolygon[]): PolygonItem[] => {
	const resultPolygons = data.map((polygon) => {
		const { id, irPoints, name, temp_max, temp_min, startSize } = polygon;

		irPoints.forEach((point) => {
			const { newSize } = canvasState.canvasReSize;

			const FW = 1920;
			const FH = 1080;

			const NW = newSize.width;
			const NH = newSize.height;

			point.x = Math.round((point.x / FW) * NW);
			point.y = Math.round((point.y / FH) * NH);
		});

		return {
			id,
			name,
			temp_min,
			temp_max,
			points: irPoints,
			startSize,
			attributes: {
				type: 'notype',
				fillColor: 'rgba(170, 170, 170, 0.3)',
				rgba: { r: 170, g: 170, b: 170, a: 0.3 },
			},
		};
	});

	return resultPolygons;
};

export const getCameraParamsQuery = async (link: string) => {
	const { setPatrolType } = presetState;
	const data = await apiGetQuery(link);

	const cameraParams = prepareCameraParams(data as unknown as PrepareCamParams);
	const { patrolDelay, patrolSchedule } = cameraParams;

	const patrolTypesKey = `${patrolSchedule.length > 0 && patrolSchedule[0] !== ''}-${patrolDelay > 0}`;
	setPatrolType(patrolTypes[patrolTypesKey]);
};

export const prepareCameraParams = (data: PrepareCamParams) => {
	const { id_psw, patrol_delay, patrol_every_mins, patrol_schedule, poll_delay, poll_schedule } = data;

	const dataCamParams = {
		id: id_psw,
		patrolDelay: Math.round(patrol_delay / 3600),
		patrolEveryMins: patrol_every_mins,
		patrolSchedule:
			patrol_schedule && patrol_schedule.length !== 0 ? patrol_schedule.map((time) => `${time}:00`) : [''],
		pollDelay: Math.round(poll_delay / 3600),
		pollSchedule: poll_schedule && poll_schedule.length !== 0 ? poll_schedule.map((time) => `${time}:00`) : [''],
	};

	return dataCamParams;
};

export const preperaPresetSettings = (data: PrepareSettings): PresetSettings => {
	const {
		data_sound_corr,
		data_sound_energy,
		data_sound_frequency,
		id,
		id_psw,
		is_deleted,
		name,
		preset_max_temp,
		preset_min_temp,
		preset_number,
		threshold_uv,
	} = data;
	return {
		dataSoundCorr: data_sound_corr,
		dataSoundEnergy: data_sound_energy,
		dataSoundFrequency: data_sound_frequency,
		id,
		idPsw: id_psw,
		isDeleted: is_deleted,
		name,
		presetMaxTemp: preset_max_temp,
		presetMinTemp: preset_min_temp,
		presetNumber: preset_number,
		thresholdUv: threshold_uv,
	};
};
