import connectState from '@store/connectState/connectState';

export const setConnections = () => {
	// const testHost = 'msd-dev.aizavod.ru';
	const host = 'msd.ss24h.com';
	//Новый сервер
	const domain = `https://${host}/api/`;

	const mapLink = `https://${host}/map/`;
	const authPath = 'auth/';

	connectState.setUserLink(`${domain}${authPath}profile/`);
	connectState.setGetCameraInfo(`${domain}camera/`);
	connectState.setGetPlaceInfo(`${domain}spot/`);

	connectState.setReadNotification(`${domain}notifications/`);

	connectState.setGetAllUserAccess(`${domain}${authPath}getalluseraccess/`);
	connectState.setPostUserAccess(`${domain}${authPath}createuseraccess/`);
	connectState.setDeleteUserAccess(`${domain}${authPath}deleteuseraccess/`);
	connectState.setChangeUserAccess(`${domain}${authPath}changeuseraccess/`);
	connectState.setChangeUser(`${domain}${authPath}user/`);
	connectState.setDeleteUser(`${domain}${authPath}user/`);
	connectState.setUserCameras(`${domain}${authPath}user-cameras/`);
	connectState.setGetAllUsersLink(`${domain}${authPath}users/`);

	connectState.setPostUserLink(`${domain}${authPath}register/`);
	connectState.setGetGroup(`${domain}group/getgroups/`);

	connectState.setGetFreePresets(`${domain}camera/`);
	connectState.setAiEventLink(`${domain}aievents/`);
	connectState.setGetPresetsAnaliticaLink(`${domain}camera/`);

	connectState.setLinkMap(`${mapLink}`);

	// connectState.setPostAutorizeLink(`${domain}${authPath}token/login/`);
	//Новый сервер
	connectState.setPostAutorizeLink(`${domain}${authPath}login/`);
	connectState.setPostLogoutLink(`${domain}${authPath}logout/`);

	connectState.setGetPlacesLink(`${domain}spot/`);

	connectState.setGetDevicesLink(`${domain}spot/`);

	connectState.setGetEventsLink(`${domain}camera/`);
	connectState.setPostPlace(`${domain}group/`);
	connectState.setPostUserAction(`${domain}logs/createlog/`);
	connectState.setGetTechUsers(`${domain}${authPath}get-tech-users/`);

	connectState.setGetUsersActionsLink(`${domain}logs/getlogs/`);

	connectState.setLinkPolygons(`${domain}zones/`);

	connectState.setNotificationsLink(`${domain}notifications/get/?isReaded=false`);
	connectState.setReadAllNotificationsLink(`${domain}notifications/readall/`);

	connectState.setWSLink(`wss://${host}/ws/camera/`);
	connectState.setWSLinkNotifications(`wss://${host}/ws/notifications`);

	connectState.setLinkManual(`https://${host}/assets/userManual.pdf`);
	connectState.setLinkDeviceManual(`https://${host}/assets/deviceManual.pdf`);
};

export const checkNegativeServerAns = (status: number) => {
	const positivesAns: { [key: string]: boolean } = { 200: false, 201: false, 204: false };

	return positivesAns[`${status}`] === undefined;
};

export const temperStrToArrayObjects = (str: string): number[][] => {
	const prepareGrid = str
		.replace(/[\\[\]]/g, '')
		.split('; ')
		.map((value) => value.split(', ').map((item) => Number((+item).toFixed(1))));

	// Транспонируем матрицу данных
	const result = prepareGrid[0].map((_, colIndex) => prepareGrid.map((row) => row[colIndex]));

	return result;
};

export const temperStrToArrayNumbers = (str: string): number[][] => {
	const tempStr = str.replace(/[\\[\]]/g, '').replace(/[/;]/g, ',');

	const fullArr = tempStr.split(', ').map((item) => Number((+item).toFixed(1)));

	const row = 24;
	const column = 32;

	let counter = 0;
	const result: number[][] = [];

	for (let i = 0; i < row; ++i) {
		result[i] = [];

		for (let j = 0; j < column; ++j) {
			result[i].push(fullArr[counter++]);
		}
	}

	return result;
};

export const middleTemperature = (str: string): number => {
	const tempStr = str.replace(/[\\[\]]/g, '').replace(/[/;]/g, ',');

	const fullArr = tempStr.split(', ').map((item) => Number((+item).toFixed(4)));

	let result = 0;

	fullArr.forEach((num) => (result += num));

	return Number((result / fullArr.length).toFixed(1));
};

export const dateToEnDate = (date = new Date()) => {
	const offset = date.getTimezoneOffset();
	const rawDate = new Date(date.getTime() - offset * 60 * 1000);
	const enDate = rawDate.toISOString().split('T')[0];

	return enDate;
};
