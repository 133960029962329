import { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './TranslationMain.module.scss';
import { useNavigate } from 'react-router-dom';
import MainButton from '@helpers/components/MainButton/MainButton';
import BackIcon from '@assets/Icons/LeftMenu/BackIcon';
import FieldSearchPlaces from '@components/FieldSearchPlaces/FieldSearchPlaces';
import { observer } from 'mobx-react-lite';
import Modal from '@mui/material/Modal';
import translationState from '@store/translationState/translationState';
import canvasState from '@store/canvasState/canvasState';
import FireIcon from '@assets/Icons/ControlPage/FireIcon';
import TempIcon from '@assets/Icons/ControlPage/TempIcon';
import CircleIcon from '@assets/Icons/Translation/CircleIcon';
import ViewBox from './ViewBox/ViewBox';
import useWindowDimensions from '@helpers/functions/useWindowDimensions';
import { ClickPosition } from './ViewBox/ViewBox.interface';
import FourLeafCloverControl from './FourLeafCloverControl/FourLeafCloverControl';
import Controller from './FourLeafCloverControl/Controller/Controller';
import KeyboardControl from './FourLeafCloverControl/KeyboardControl/KeyboardControl';
import MouseControl from './FourLeafCloverControl/MouseControl/MouseControl';
import ControlIconsBottom from './ControlIconsBottom/ControlIconsBottom';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { TranslationStateTypeContnetModal as TypeContentModal } from '@store/translationState/interfaces';
import DeleteModalArea from './Canvas/DeleteModalArea/DeleteModalArea';
import ParamsPatrolModal from './ControlIconsBottom/ParamsPatrolModal/ParamsPatrolModal';
import SettingsEvent from './ControlIconsBottom/SettingsEvent/SettingsEvent';
import connectState from '@store/connectState/connectState';
import autorizeState from '@store/accoutState/autorizeState';
import devicesState from '@store/devicesState/devicesState';
import { useQuery, useQueryClient } from 'react-query';
import { getEventsQuery, prepareEvent } from '@api/Control/prepareControlData';
import { WebSocketCommands } from '@pages/Pages.interface';
import { ResultEventData } from '@store/eventState/interface';
import Polygons from './Canvas/Polygons';
import {
	callPresetCommand,
	createPresetCommand,
	deletePresetCommand,
	getActivePreset,
	moveAboutPoint,
	moveCamera,
	patrolUpdateCommand,
	pollUpdate,
} from './wsWorking/wsCommands';
import presetState from '@store/presetState/presetState';
import { getCameraParamsQuery, getPolygonsQuery, getPresetsQuery } from '@api/Translation/prepareTranslationData';

const TranslationMain: FC = observer(() => {
	const {
		isMouseControl,
		isVisibleTemp,
		isVisibleTempGrid,
		isLiveStream,
		isVisibleController,
		isOpenModal,
		contentTypeModal,
		newPreset,
		selectComandArrow,
		isGetEventPOLL,
		setOpenModal,
		setVisibleTemp,
		setVisibleTempGrid,
		setIsLiveStream,
	} = translationState;
	const { selectDeviceId } = devicesState;
	const { isShowCanvas, isDeletePolygon, setCanvas, setCanvasVisible } = canvasState;
	const { getEventsLink, linkPolygons, getCameraInfo } = connectState;
	const {
		isSavePreset,
		isDeletePreset,
		deletePreset,
		selectPresetNumWs,
		patrolCommandWs,
		selectPresetId,
		isEditPolygonsArr,
		setSelectPresetId,
		setCurrentNumPresetWs
	} = presetState;

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const contentModal: TypeContentModal = {
		DeleteArea: <DeleteModalArea />,
		PatrolParams: <ParamsPatrolModal />,
		SettingsEvent: <SettingsEvent />,
	};

	const contentRef = useRef<HTMLDivElement | null>(null);
	const socket = useRef<WebSocket | null>(null);

	const [imgBackground, setImgBackground] = useState('');
	const [clickPosition, setClickPosition] = useState<ClickPosition>({ x: null, y: null });
	const [tempGrid, setTempGrid] = useState<number[][]>([]);
	const [tCurrent, setTCurrent] = useState<number>(0);
	const [isVisibleMax, setVisibleMax] = useState(false);

	const [isReconnect, setIsReconnect] = useState(false);
	const [isFirstConnect, setFirstConnect] = useState(true);

	const maxTemp = useMemo(() => Math.max(...tempGrid.map((row) => Math.max(...row))), [tempGrid]);
	const minTemp = useMemo(() => Math.min(...tempGrid.map((row) => Math.min(...row))), [tempGrid]);

	const visibleControlsButton = !isMouseControl && !isShowCanvas;
	const { width, height } = useWindowDimensions();

	const changeW = width - 151;
	const changeH = height - 176;

	const newHeightCanvas = (9 / 16) * changeW;
	const newWidthCanvas = (16 / 9) * changeH;
	const compareHeight = newHeightCanvas < changeH;
	const boxSizeCanvas = { w: compareHeight ? changeW : newWidthCanvas, h: compareHeight ? newHeightCanvas : changeH };

	useQuery(
		['getPresets', getEventsLink, selectDeviceId],
		() => getPresetsQuery(`${getEventsLink}${selectDeviceId}/getpresets/`),
		{
			enabled: !!selectDeviceId,
		}
	);

	useQuery(
		['getCameraParams', getCameraInfo, selectDeviceId],
		() => getCameraParamsQuery(`${getCameraInfo}${selectDeviceId}/getpcameraparameters/`),
		{
			enabled: !!selectDeviceId,
		}
	);

	useQuery(
		['getPolygons', linkPolygons, selectPresetId],
		() => getPolygonsQuery(`${linkPolygons}${selectDeviceId}/${selectPresetId}/getzonelist/`),
		{
			enabled: !!selectPresetId,
		}
	);

	useQuery(
		['getEvents', getEventsLink, selectDeviceId],
		() => getEventsQuery(`${getEventsLink}${selectDeviceId}/getevents/`),
		{
			enabled: !!selectDeviceId,
			onSuccess({ dataEvents }) {
				if (!dataEvents.length) {
					setTempGrid([]);
					setImgBackground('');
				} else {
					setTempGrid(dataEvents[0].viewData.temperGrid);
					setImgBackground(dataEvents[0].img);
				}
			},
		}
	);

	useEffect(() => {
		if (!isLiveStream) return;

		const intervalPool = setInterval(() => {
			getActivePreset(socket.current);
		}, 60000);

		return () => clearInterval(intervalPool);
	}, [isLiveStream]);

	useEffect(() => {
		let newSocket: WebSocket | null = null;

		const intervalId = setInterval(() => {
			try {
				const { wsLink } = connectState;
				const { token } = autorizeState;

				if (!selectDeviceId) return;
				const linkConnect = `${wsLink}${selectDeviceId}/?token=${token}&page=camerapage`;

				newSocket = new WebSocket(linkConnect);
				newSocket.onopen = () => {
					getActivePreset(socket.current);
					if (isFirstConnect) return;
					toast.success('Соединение установлено.', {
						autoClose: 2000,
						className: styles.toastMessage,
					});
				};
				newSocket.onmessage = (e) => socketMessage(e);
				newSocket.onerror = (e) => socketError(e);
				newSocket.onclose = (e) => socketClose(e);

				socket.current = newSocket;

				clearInterval(intervalId);
			} catch (error) {
				console.error(error);
			}
		}, 1500);

		return () => {
			try {
				if (newSocket instanceof WebSocket) newSocket.close();

				clearInterval(intervalId);
				setVisibleTempGrid(false);
				setVisibleTemp();
				setCanvasVisible(false);
				setCanvas(null);
			} catch (error) {
				console.error(error);
			}
		};
	}, [selectDeviceId, isReconnect]);

	const socketError = (error: Event) => {
		console.error(error);
	};

	const socketClose = (event: CloseEvent) => {
		const closeConnectMsg = 'Соединение закрыто чисто';
		const breakConnectMsg = 'Обрыв соединения';

		if (event.wasClean) console.log(closeConnectMsg);
		else {
			toast.error(`${breakConnectMsg}, Повторное соединение...`, {
				autoClose: 2000,
				className: styles.toastError,
			});
			setFirstConnect(false);
			setIsReconnect((prevIsBreak) => !prevIsBreak);
		}
	};

	const socketMessage = (event: MessageEvent) => {
		try {
			const { command, data, success } = JSON.parse(event.data);
			console.log(JSON.parse(event.data));

			const actions: WebSocketCommands = {
				NEWEVENT: () => addNewEvent(prepareEvent(data)),
				// eslint-disable-next-line
				CAMERACHANGED: () => {},
				// eslint-disable-next-line
				ZONECHANGED: () => queryClient.invalidateQueries('getPolygons'),
				PRESETCHANGED: () => queryClient.invalidateQueries('getPresets'),
				CAMERASTATUS: () => setIsLiveStream(data.status),
				// eslint-disable-next-line
				UPDATEPRESETLIST: () => getActivePreset(socket.current),

				POLL: () => !success && errorCommand(),
				MOVE: () => !success && errorCommand(),
				MOVEPTZPOINT: () => !success && errorCommand(),
				PATROLUPDATE: () => {
					if (success) {
						queryClient.invalidateQueries('getPresets');
						queryClient.invalidateQueries('getCameraParams');
					} else {
						errorCommand();
					}
				},

				//Пресеты
				GETACTIVEPRESET: () => setCurrentNumPresetWs(Number(data)),
				CALLPRESET: () => {
					if (success) {
						getActivePreset(socket.current);
					} else {
						errorCommand();
					}
				},
				CREATEPRESET: () => {
					if (success) {
						queryClient.invalidateQueries('getPresets');
					} else {
						errorCommand();
					}
				},
				DELETEPRESET: () => {
					if (success) {
						queryClient.invalidateQueries('getPresets');
					} else {
						errorCommand();
					}
				},
			};

			actions[command]();
		} catch (error) {
			console.log('Не получилось прочитать ответ');
			console.error(error);
		}
	};

	useEffect(() => {
		const { w, h } = boxSizeCanvas;
		if (w !== 0) canvasState.setCanvasReSize(w * 0.55, h * 0.55);

		if (isShowCanvas) new Polygons(canvasState.canvas);
	}, [width, height, changeW, isEditPolygonsArr]);

	useEffect(() => {
		if (!isShowCanvas) return;
		new Polygons(canvasState.canvas);
	}, [isShowCanvas, selectDeviceId, selectPresetId, isEditPolygonsArr]);

	useEffect(() => {
		moveCamera(selectComandArrow, socket.current);
	}, [selectComandArrow]);

	useEffect(() => {
		pollUpdate(selectComandArrow, socket.current);
	}, [isGetEventPOLL]);

	useEffect(() => {
		const point = calculatePosition(clickPosition);

		moveAboutPoint(point, socket.current);
	}, [clickPosition]);

	useEffect(() => {
		if (!selectPresetNumWs) return;
		callPresetCommand(selectPresetNumWs, socket.current);
	}, [selectPresetNumWs]);

	useEffect(() => {
		createPresetCommand(newPreset, socket.current);
	}, [isSavePreset]);

	useEffect(() => {
		if (!deletePreset) return;
		deletePresetCommand(deletePreset, socket.current);
	}, [isDeletePreset]);

	useEffect(() => {
		if(!patrolCommandWs) return;
		patrolUpdateCommand(patrolCommandWs, socket.current);
	}, [patrolCommandWs, isDeletePolygon]);

	const sendPullUpdate = () => {
		toast.success('Запрос отправлен.', {
			autoClose: 2000,
			className: styles.toastMessage,
		});
	};

	const addNewEvent = (event: ResultEventData) => {
		setTempGrid(event.viewData.temperGrid);
		setImgBackground(event.img);

		if (!event.idPreset) return;

		setSelectPresetId(event.idPreset);
	};

	const errorCommand = () => {
		toast.error('Команда не прошла, попробуйте позже.', {
			autoClose: 2000,
			className: styles.toastMessage,
		});
	};

	const calculatePosition = ({ x, y }: ClickPosition) => {
		if (!x || !y) return { x, y };

		const point = {
			x: 0,
			y: 0,
		};

		const FW = 1920;
		const FH = 1080;

		const NW = boxSizeCanvas.w;
		const NH = boxSizeCanvas.h;

		point.x = (x / NW) * FW;
		point.y = (y / NH) * FH;

		return point;
	};

	const handleGoBack = () => {
		navigate(-1);
	};
	return (
		<div className={styles.container}>
			<ToastContainer />

			<MainButton className={`${styles.buttonPos} ${styles.topLeft}`} onClick={handleGoBack}>
				<BackIcon />
			</MainButton>

			<FieldSearchPlaces />

			<div ref={contentRef} className={styles.content}>
				{isMouseControl && <span className={styles.tempButton}>Режим управления по клику</span>}

				{isVisibleTemp && isVisibleTempGrid && !isShowCanvas && (
					<div className={styles.currentTemp}>
						<div className={styles.currentTitle}>{`t°C Текущая: ${tCurrent} °C`}</div>
						<div className={styles.currentTitle}>{`t°C Макс: ${maxTemp} °C`}</div>
					</div>
				)}

				{isVisibleTempGrid && !isMouseControl && !isShowCanvas && (
					<div className={styles.fireIcon}>
						<MainButton onClick={() => setVisibleTemp(!isVisibleTemp)} disabled={!tempGrid.length}>
							<TempIcon fill={isVisibleTemp ? '#00C2FD' : '#D2D2D2'} disabled={!tempGrid.length} />
						</MainButton>
						<MainButton onClick={() => setVisibleMax(!isVisibleMax)} disabled={!tempGrid.length}>
							<FireIcon fill={isVisibleMax ? '#00C2FD' : '#D2D2D2'} disabled={!tempGrid.length} />
						</MainButton>
					</div>
				)}

				<div className={styles.online}>
					<div className={styles.btnOnline}>
						<CircleIcon fill={isLiveStream ? 'green' : 'red'} />
					</div>
				</div>

				<ViewBox
					width={boxSizeCanvas.w}
					height={boxSizeCanvas.h}
					isVisibleMax={isVisibleMax}
					img={imgBackground}
					tempGrid={tempGrid}
					minTemp={minTemp}
					maxTemp={maxTemp}
					clickPosition={clickPosition}
					setTCurrent={setTCurrent}
					setClickPosition={setClickPosition}
				/>

				{visibleControlsButton && isVisibleController && (
					<div className={styles.cameraControlView}>
						<FourLeafCloverControl />
					</div>
				)}

				<div className={styles.additionalButtons}>
					<Controller />

					<KeyboardControl />

					<MouseControl />
				</div>
			</div>

			<Modal open={isOpenModal} onClose={() => setOpenModal(false)}>
				<div>{contentModal[contentTypeModal]}</div>
			</Modal>

			<ControlIconsBottom sendPullUpdate={sendPullUpdate} />
		</div>
	);
});

export default TranslationMain;
