import { MetaTags } from '@pages/Pages.interface';

export const metaControlPage: MetaTags = {
	title: 'События',
	description: 'Страница просмотра событий и управления камерой',
	keywords: 'события, msd',
};

export const metaTranslationPage: MetaTags = {
	title: 'Трансляция',
	description: 'Страница просмотра прямого эфира с камеры',
	keywords: 'translation, msd',
};

export const metaMapPage: MetaTags = {
	title: 'Карта - объекты',
	description: 'Основная страница сайта MSD. Поиск элементов на карте',
	keywords: 'msd',
};

export const metaMapDevicesPage: MetaTags = {
	title: 'Карта - камеры',
	description: 'Страница отображения камер по выбранному месту',
	keywords: 'cameras',
};

export const metaDashboardPage: MetaTags = {
	title: 'Информационная панель',
	description: 'Описание страницы',
	keywords: 'ключевые слова',
};

export const metaAccountPage: MetaTags = {
	title: 'Аккаунт',
	description: 'Описание страницы',
	keywords: 'ключевые слова',
};

export const metaAnaliticaPage: MetaTags = {
	title: 'Аналитика событий',
	description: 'Описание страницы',
	keywords: 'ключевые слова',
};
