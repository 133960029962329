import { List } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ListItemsProps } from './ListItems.interface';
import styles from './ListItems.module.scss';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Device } from '../../../../store/devicesState/interface';
import { PlaceDevices as Place } from '../../../../store/placeState/interfaces';
import devicesState from '../../../../store/devicesState/devicesState';
import placeState from '../../../../store/placeState/placeState';
import { useNavigate } from 'react-router-dom';
import mapState from '../../../../store/mapState/mapState';
import type { LatLngExpression } from 'leaflet';
import { useQueryClient } from 'react-query';

const styleForList = () => ({
	'&.MuiList-root': {
		width: 'calc(100% - 20px)',

		padding: '0 10px',

		top: '52px',
		bottom: '0',

		transition: 'height 0.3s ease-in-out',

		'&::-webkit-scrollbar': {
			width: '6px',
		},

		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#ccc',
			borderRadius: '3px',
		},
	},
});

const StyledList = styled(List)(styleForList);

type CenterMapChange = (
	id: string,
	center: LatLngExpression,
	name: string,
) => void;

const ListItems: React.FC<ListItemsProps> = observer(
	({
		isVisibleList,
		valueSearch,
		arrayItems,
		isVisibleDevices,
		isMapPage,
		isDeviceMap,
		setVisibleDevices,
		clickItem,
		isDashboardPage,
	}) => {
		const { devices, setselectDeviceId, setSelectedDevices } = devicesState;
		const { places, setSelectedPlaces, setSelectPlaceId } = placeState;
		const { setCenterMap, setPopupPosition, setZeroPopupPosition, setNamePopup } = mapState;

		const queryClient = useQueryClient();
		const navigate = useNavigate();

		const filterTimerRef = useRef<NodeJS.Timeout | null>(null);

		const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);

		const listItemsStyle = {
			height: isVisibleList ? 'calc(40vh - 52px)' : '0',
			overflow: isVisibleList ? 'auto' : 'hidden',
		};

		useEffect(() => {
			filteredItems();
		}, [valueSearch, devices]);

		const filteredItems = () => {
			if (filterTimerRef.current) {
				clearTimeout(filterTimerRef.current);
			}

			filterTimerRef.current = setTimeout(() => {
				const filtered = devices.filter(({ name }) => name.toLowerCase().includes(valueSearch.toLowerCase()));

				setFilteredDevices(filtered);
			}, 500);
		};

		const handlePlace = (idPlace: string) => {
			if (isMapPage) {
				setSelectPlaceId(idPlace);
				setSelectedPlaces(idPlace, true);

				const place = places.find(({ id }) => id === idPlace);

				if (place === undefined) return;

				setZeroPopupPosition();

				const placeName = place.name.replace(/ /g, '_').toLowerCase();
				navigate(`/device/${placeName}`);
			} else {
				queryClient.invalidateQueries('getDevices');
				setVisibleDevices(true);
			}
		};

		const handleDevice: CenterMapChange = (id, center, name) => {
			setselectDeviceId(id);
			setSelectedDevices(id);
			queryClient.invalidateQueries('getPresets');

			if (isDeviceMap) {
				const delayTimeMS = 300;

				setCenterMap(center);

				setNamePopup(name);
				setZeroPopupPosition();
				setTimeout(() => {
					setPopupPosition(center);
				}, delayTimeMS);
			}
		};

		const handleSelectCenterMap: CenterMapChange = (id, center, name) => {
			clickItem(id);

			if (isMapPage) {
				const delayTimeMS = 300;

				setCenterMap(center);

				setNamePopup(name);
				setZeroPopupPosition();
				setTimeout(() => {
					setPopupPosition(center);
				}, delayTimeMS);
			}
		};

		const createPlaceItem = ({ id, name, coords, isSelected }: Place) => {
			const key = `Control-Item-${id}-${name}`;
			const resultStyle = `${styles.placeItem} ${isSelected && styles.show}`;

			return (
				<div key={key} className={resultStyle} onClick={() => handleSelectCenterMap(id, coords, name)}>
					{name}

					{!isDashboardPage && (
						<div className={styles.navigateItem} onClick={() => handlePlace(id)}>
							к устройствам
						</div>
					)}
				</div>
			);
		};

		const createDeviceItem = ({ id, name, coords, isSelected }: Device) => {
			const key = `Control-Item-${id}-${name}`;
			const resultStyle = `${styles.placeItem} ${isSelected && styles.show}`;

			return (
				<div key={key} className={resultStyle} onClick={() => handleDevice(id, coords, name)}>
					{name}
				</div>
			);
		};

		return (
			<StyledList sx={listItemsStyle}>
				{(!!filteredDevices.length || !!arrayItems.length) && <div className={styles.titleList}>Список {isVisibleDevices || isDeviceMap ? 'устройств' : 'объектов'}</div>}

				<div className={styles.titleList}>{((isVisibleDevices || isDeviceMap) && !filteredDevices.length) && 'Устройства не найдены'}</div>

				{isVisibleDevices || isDeviceMap ? filteredDevices.map(createDeviceItem) : arrayItems.map(createPlaceItem)}
			</StyledList>
		);
	}
);

export default ListItems;
