import React, { useEffect } from 'react';
import styles from './MapPage.module.scss';
import Map from '../../components/Map/Map';
import { useNavigate } from 'react-router-dom';
import { MapPageProps as Props } from '../Pages.interface';
import HelmetTags from '../../helpers/components/HelmetTags/HelmetTags';
import { observer } from 'mobx-react-lite';
import { CheckLoading } from '../../components/CheckLoading/CheckLoading';
import FieldSearchPlaces from '../../components/FieldSearchPlaces/FieldSearchPlaces';
import MainButton from '../../helpers/components/MainButton/MainButton';
import BackIcon from '../../assets/Icons/LeftMenu/BackIcon';
import { checkPageReady } from '@helpers/functions/loading/mapLoading';

const MapPage: React.FC<Props> = observer(({ metaTags }) => {
	const navigate = useNavigate();

	useEffect(() => {
		const timeout = setTimeout(() => {
			checkPageReady('isPlaces', true);
			checkPageReady('isDevices', true);
		}, 300);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const handleGoBack = () => {
		navigate('/');
	};

	return (
		<CheckLoading page='map'>
			<div className={styles.parent}>
				<HelmetTags metaTags={metaTags} />

				<MainButton className={`${styles.buttonPos} ${styles.topLeft}`} onClick={handleGoBack}>
					<BackIcon />
				</MainButton>

				<FieldSearchPlaces isMapPage={true} />

				<div className={styles.container}>
					<Map />
				</div>
			</div>
		</CheckLoading>
	);
});

export default MapPage;
