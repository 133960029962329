import React, { useState, useEffect, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { List, Modal } from '@mui/material';
import styles from './PresetsList.module.scss';
import PresetsModals from './PresetsModals/PresetsModals';
import { PresetsListProp } from '../../FieldSearchPlaces.interface';
import SettingIcon from '@assets/Icons/Translation/SettingIcon';
import PlusIcon from '@assets/Icons/Translation/PlusIcon';
import DeleteIcon from '@assets/Icons/Translation/DeleteIcon';
import PencilIcon from '@assets/Icons/Translation/Pencil';
import { Preset } from '@store/presetState/interfaces';
import devicesState from '@store/devicesState/devicesState';
import placeState from '@store/placeState/placeState';
import presetState from '@store/presetState/presetState';
import autorizeState from '@store/accoutState/autorizeState';

const styleForList = () => ({
	'&.MuiList-root': {
		width: 'calc(100% - 20px)',

		padding: '0 10px',

		top: '52px',
		bottom: '0',

		transition: 'height 0.3s ease-in-out',

		'&::-webkit-scrollbar': {
			width: '6px',
		},

		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#ccc',
			borderRadius: '3px',
		},
	},
});

const StyledList = styled(List)(styleForList);

const PresetsList: React.FC<PresetsListProp> = observer(
	({ isVisibleList, isSearchPreset, arrayItems, isPatrol, clickPreset, closeMenu }) => {
		const { selectDeviceId, devices } = devicesState;
		const { selectPlaceId, places } = placeState;
		const { currentNumPresetWs, setPatrolCommandWs, patrolType } = presetState;
		const { userRole } = autorizeState;

		const { pathname } = useLocation();
		const deviceName = devices.find((device) => device.id === selectDeviceId);
		const placeName = places.find((place) => place.id === selectPlaceId);

		const [isOpenModal, setIsOpenModal] = useState(false);
		const [isEditPreset, setEditPreset] = useState(false);
		const [selectPreset, setSelectPreset] = useState<Preset | undefined>();
		const [presetEdit, setPresetEdit] = useState<Preset | undefined>();
		const [modalType, setModalType] = useState('');
		const [isAnalitica, setIsAnalitica] = useState(false);

		useEffect(() => {
			setIsAnalitica(pathname === '/analitica');
		}, [pathname]);

		const listItemsStyle = {
			height: isVisibleList ? 'calc(40vh - 52px)' : '0',
			overflow: isVisibleList ? 'auto' : 'hidden',
		};

		const editIcons = [
			{
				id: 1,
				icon: <PencilIcon width={22} height={22} />,
				click: (e: MouseEvent<HTMLDivElement>, preset: Preset) => clickEditsIcon(e, 'editName', preset),
			},
			{
				id: 2,
				icon: <DeleteIcon width={23} height={23} />,
				click: (e: MouseEvent<HTMLDivElement>, preset: Preset) => clickEditsIcon(e, 'delete', preset),
			},
		];

		useEffect(() => {
			const selectElement = arrayItems.find(({ number }) => number === currentNumPresetWs);

			setSelectPreset(selectElement);
		}, [arrayItems, currentNumPresetWs]);

		useEffect(() => {
			if (!isVisibleList) {
				setEditPreset(false);
			}
		}, [isVisibleList]);

		useEffect(() => {
			if (modalType !== '') setIsOpenModal(true);
		}, [modalType]);

		const handlePatrolList = () => {
			setPatrolCommandWs(!isPatrol ? patrolType : 'off');
		};

		const handleCloseModal = () => {
			setIsOpenModal(false);
			setEditPreset(false);
			setModalType('');
		};

		const clickSettings = (e: MouseEvent<HTMLDivElement>) => {
			e.stopPropagation();
			setEditPreset(true);
		};

		const clickEditsIcon = (e: MouseEvent<HTMLDivElement>, type: string, preset: Preset) => {
			e.stopPropagation();
			setPresetEdit(preset);
			setModalType(type);
		};

		const iconsItem: { [key: string]: ( preset: Preset ) => JSX.Element } = {
			true: ( preset ) => (
				<div style={{ display: 'flex', gap: 6 }}>
					{editIcons.map(({ id, icon, click }) => {
						return (
							<div key={`${id}-${name}`} className={styles.iconSet} onClick={(e) => click(e, preset)}>
								{icon}
							</div>
						);
					})}
				</div>
			),
			false: () => (
				<div className={styles.iconSet} onClick={clickSettings}>
					<SettingIcon width={23} height={23} />
				</div>
			),
		};

		const createPresetItem = ( preset: Preset ) => {
			const { id, name, number } = preset;
			const key = `Control-Item-${id}-${name}`;
			const isActive = number === currentNumPresetWs;
			const resultStyle = `${styles.placeItem} ${isActive && styles.show}`;

			return (
				<div key={key} className={resultStyle} onClick={() => clickPreset(id, number)}>
					<div onClick={closeMenu}>{name}</div>

					{userRole !== 'spotoperator' && (
						<div className={`${isAnalitica && styles.analiticaStyle}`}>
							{iconsItem[isEditPreset.toString()](preset)}
						</div>
					)}
				</div>
			);
		};

		return (
			<StyledList sx={listItemsStyle}>
				{isSearchPreset && (
					<div className={`${isAnalitica && styles.analiticaStyle}`}>
						<div className={styles.patrolHeader}>
							<div style={{ display: 'flex' }}>
								Патрулирование:
								<div className={styles.handlerPatrol} onClick={handlePatrolList}>
									{isPatrol ? 'Вкл' : 'Выкл'}
								</div>
							</div>
							<div>{selectPreset?.name}</div>
						</div>

						{userRole !== 'spotoperator' && (
							<div className={styles.addPreset} onClick={() => setModalType('create')}>
								<div className={styles.iconAdd}>
									<PlusIcon />
								</div>
								Создать пресет
							</div>
						)}
					</div>
				)}

				{arrayItems.map(createPresetItem)}

				<Modal className={styles.modal} open={isOpenModal} onClose={handleCloseModal}>
					<div className={styles.modalContent}>
						<PresetsModals
							type={modalType}
							selectPreset={presetEdit}
							idDevice={deviceName?.id}
							deviceName={deviceName?.name}
							placeName={placeName?.name}
							setOpenModal={setIsOpenModal}
							setEditPreset={setEditPreset}
							setModalType={setModalType}
						/>
					</div>
				</Modal>
			</StyledList>
		);
	}
);

export default PresetsList;
