import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ControlPage.module.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { observer } from 'mobx-react-lite';
import { ControlPageProps as Props, WebSocketCommands } from '../Pages.interface';
import HelmetTags from '@helpers/components/HelmetTags/HelmetTags';
import MainButton from '@helpers/components/MainButton/MainButton';
import BackIcon from '@assets/Icons/LeftMenu/BackIcon';
import { apiGet } from '@api/api';
import connectState from '@store/connectState/connectState';
import { CheckLoading } from '@components/CheckLoading/CheckLoading';
import FieldSearchPlaces from '@components/FieldSearchPlaces/FieldSearchPlaces';
import ScrinEventCamera from '@components/Control/ScrinEventCamera/ScrinEventCamera';
import InfographicsEvent from '@components/Control/InfographicsEvent/InfographicsEvent';
import devicesState from '@store/devicesState/devicesState';
import { prepareControlEvents, prepareEvent, preparePolygon } from '@api/Control/prepareControlData';
import { RawDataEvents } from '@api/Control/prepareControlData.interface';
import InfoEvent from '@components/Control/InfoEvent/InfoEvent';
import TableEvents from '@components/Control/TableEvents/TableEvents';
import eventState from '@store/eventState/eventState';
import ControlEventsIcons from '@components/Control/ControlEventsIcons/ControlEventsIcons';
import { ResultEventData } from '@store/eventState/interface';
import { getDefEvent } from '@helpers/defaultsData/defaultsData';
import Modal from '@mui/material/Modal';
import controlState from '@store/controlState/controlState';
import UnloadingEvents from '@components/Control/UnloadingEvents/UnloadingEvents';
import PrepareProtocol from '@components/Control/PrepareProtocol/PrepareProtocol';
import Sensors from '@components/Control/Sensors/Sensors';
import { PreparePolygonControl } from '@store/presetState/interfaces';
import canvasState from '@store/canvasState/canvasState';
import translationState from '@store/translationState/translationState';
import autorizeState from '@store/accoutState/autorizeState';
import { checkPageReady } from '@helpers/functions/loading/controlsLoading';
import { pollUpdate } from '@components/Translation/wsWorking/wsCommands';

const ControlPage: React.FC<Props> = observer(({ metaTags }) => {
	const navigate = useNavigate();
	const { getEventsLink, linkPolygons, wsLink } = connectState;
	const { selectDeviceId } = devicesState;
	const {
		isShowPrepareProtocol,
		selectEventId,
		isGetNewEvents,
		events,
		zoneSelectEvent,
		selectPresetId,
		setEvents,
		setSelectedEventId,
		setShowPrepareProtocol,
		setIsGetNewEvents,
		setAddNewEvent,
	} = eventState;
	const { isUnloadingEvents, isControlPage, setUnloadingEvent, setVisibleTempGrid, setVisibleTemp } = controlState;
	const { isGetEventPOLL, selectComandArrow } = translationState;
	const { setCanvas, setRawData } = canvasState;

	const [countRows, setCountRows] = useState<number>(0);
	const [isLoadingTable, setIsLoadingTable] = useState(false);
	const [lastLinkEvents, setLastLinkEvents] = useState('');
	const [nextLinkEvents, setNextLinkEvents] = useState('');
	const [currentLinkEvents, setCurrentLinkEvents] = useState('');
	const [pageSizeTable, setPageSizeTable] = useState(10);
	const [isReconnect, setIsReconnect] = useState(false);

	const [event, setEvent] = useState<ResultEventData>(getDefEvent());
	const screenCameraRef = useRef<HTMLDivElement>(null);

	const [widthScreen, setWidthScreen] = useState(0);
	const [heightScreen, setHeightScreen] = useState(0);

	const [isFirstConnect, setFirstConnect] = useState(true);

	const socket = useRef<WebSocket | null>(null);

	const tableEvents = {
		rowCount: countRows,
		prevLink: lastLinkEvents,
		nextLink: nextLinkEvents,
		currentLink: currentLinkEvents,
		isLoad: isLoadingTable,
		eventId: selectEventId,
		pageSize: pageSizeTable,
		setLoad: setIsLoadingTable,
		updateEvents: (url: string) => getEvents(url),
		handlePageSize: (size: number) => setPageSizeTable(size),
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			checkPageReady('isPlaces', true);
			checkPageReady('isDevices', true);
		}, 300);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		if (!screenCameraRef.current) return;

		setWidthScreen(screenCameraRef.current.offsetWidth - 3);
		setHeightScreen(screenCameraRef.current.offsetHeight - 2);
	});

	useEffect(() => {
		return () => {
			try {
				if (socket instanceof WebSocket) socket.close();
			} catch (error) {
				console.error(error);
			}
		};
	}, [socket]);

	useEffect(() => {
		let newSocket: WebSocket | null = null;

		const intervalId = setInterval(() => {
			try {
				const { token } = autorizeState;

				if (!selectDeviceId) return;
				const linkConnect = `${wsLink}${selectDeviceId}/?token=${token}&page=eventpage`;

				newSocket = new WebSocket(linkConnect);
				newSocket.onopen = () => {
					if (isFirstConnect) return;
					toast.success('Соединение установлено.', {
						autoClose: 2000,
						className: styles.toastMessage,
					});
				};

				newSocket.onmessage = (e) => socketMessage(e);

				newSocket.onerror = (e) => socketError(e);

				newSocket.onclose = (e) => socketClose(e);

				socket.current = newSocket;

				clearInterval(intervalId);
			} catch (error) {
				console.error(error);
			}
		}, 1500);

		return () => {
			try {
				if (newSocket instanceof WebSocket) newSocket.close();

				clearInterval(intervalId);
				setVisibleTempGrid(false);
				setVisibleTemp();
				setIsGetNewEvents(true);
				setCanvas(null);
			} catch (error) {
				console.error(error);
			}
		};
	}, [selectDeviceId, isReconnect, wsLink]);

	useEffect(() => {
		pollUpdate(selectComandArrow, socket.current);
	}, [isGetEventPOLL]);

	useEffect(() => {
		const controller = new AbortController();

		if (!isGetNewEvents || getEventsLink === '' || selectDeviceId === '') return;

		const url = `${getEventsLink}${selectDeviceId}/getevents/?ordering&page_size=${pageSizeTable}`;
		getEvents(url, controller);

		return () => controller.abort();
	}, [getEventsLink, selectDeviceId, isGetNewEvents, pageSizeTable]);

	useEffect(() => {
		if (!events.length) {
			setEvent(getDefEvent());
		} else {
			const index = events.findIndex(({ id }) => id === selectEventId);

			if (!events[index]) {
				setEvent(events[0]);
			} else {
				setEvent(events[index]);
			}
		}
	}, [selectEventId, events]);

	useEffect(() => {
		const controller = new AbortController();

		if (!zoneSelectEvent || !selectPresetId || linkPolygons === '') return;

		const url = `${linkPolygons}${selectDeviceId}/getzoneinfo/${zoneSelectEvent}`;

		getZoneEvent(url, controller);

		return () => controller.abort();
	}, [zoneSelectEvent]);

	const socketMessage = (event: MessageEvent) => {
		try {
			const { command, data, success } = JSON.parse(event.data);
			console.log(JSON.parse(event.data));

			const actions: WebSocketCommands = {
				NEWEVENT: () => addNewEventTable(prepareEvent(data)),
				POLL: () => !success && errorCommand(),
			};

			actions[command]();
		} catch (error) {
			console.log('Не получилось прочитать ответ');
			console.error(error);
		}
	};

	const socketError = (error: Event) => {
		console.error(error);
	};

	const socketClose = (event: CloseEvent) => {
		const closeConnectMsg = 'Соединение закрыто чисто';
		const breakConnectMsg = 'Обрыв соединения';

		if (event.wasClean) console.log(closeConnectMsg);
		else {
			toast.error(`${breakConnectMsg}, Повторное соединение...`, {
				autoClose: 2000,
				className: styles.toastError,
			});
			setFirstConnect(false);
			setIsReconnect((prevIsBreak) => !prevIsBreak);
		}
	};

	const addNewEventTable = (event: ResultEventData) => {
		setAddNewEvent(event);
	};

	const getEvents = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;
			if (isError) return;

			const eventsData = prepareControlEvents(data as RawDataEvents);
			const { dataEvents, pagination } = eventsData;

			const selectDefaultEvent = dataEvents.length ? dataEvents[0].id : null;
			const eventIndex = dataEvents.findIndex(({ id }) => id === selectEventId);

			const isEventExist = eventIndex !== -1;

			const lastOneSelectedEvent = isEventExist ? selectEventId : selectDefaultEvent;

			setSelectedEventId(lastOneSelectedEvent);

			setEvents(dataEvents);
			setCountRows(pagination.count);
			setLastLinkEvents(pagination.previous);
			setNextLinkEvents(pagination.next);
			setIsLoadingTable(false);

			setCurrentLinkEvents(url);
		});
	};

	const getZoneEvent = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;
			if (isError) return;

			const polygons = preparePolygon(data as PreparePolygonControl);

			setRawData(selectDeviceId, selectPresetId, polygons);
		});
	};

	const sendPullUpdate = () => {
		toast.success('Запрос отправлен.', {
			autoClose: 2000,
			className: styles.toastMessage,
		});
	};

	const errorCommand = () => {
		toast.error('Команда не прошла, попробуйте позже.', {
			autoClose: 2000,
			className: styles.toastMessage,
		});
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<CheckLoading page='controls'>
			<ToastContainer />

			<div className={styles.container}>
				<HelmetTags metaTags={metaTags} />

				<MainButton className={`${styles.buttonPos} ${styles.topLeft}`} onClick={handleGoBack}>
					<BackIcon />
				</MainButton>

				<FieldSearchPlaces />

				<div className={styles.content}>
					<div className={styles.left}>
						<div ref={screenCameraRef} className={styles.topLeft}>
							<ScrinEventCamera
								widthScr={widthScreen}
								heightScr={heightScreen}
								tempGrid={event.viewData.temperGrid}
								imageEvent={event.img}
							/>
						</div>

						<div className={styles.bottomLeft}>
							<InfographicsEvent dateInfo={event?.date} />
						</div>
					</div>

					<div className={styles.right}>
						<div className={styles.topRight}>
							<TableEvents {...tableEvents} />
						</div>

						<div className={styles.bottomRight}>
							<InfoEvent event={event} />
						</div>
					</div>
				</div>
				{!isControlPage && (
					<div className={styles.modalSensors}>
						<Sensors event={event} />
					</div>
				)}

				<Modal open={isUnloadingEvents} onClose={setUnloadingEvent}>
					<div>
						<UnloadingEvents />
					</div>
				</Modal>

				<ControlEventsIcons handlePullMessage={sendPullUpdate} />

				<Modal open={isShowPrepareProtocol} onClose={setShowPrepareProtocol}>
					<div>
						<PrepareProtocol />
					</div>
				</Modal>
			</div>
		</CheckLoading>
	);
});

export default ControlPage;
