import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';

import './index.css';
import App from './App';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			//Время существования свежих данных(по дефолту - 0)
			staleTime: 1 * 60 * 1000
		}
	}
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<BrowserRouter>
		<HelmetProvider>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</HelmetProvider>
	</BrowserRouter>
);
