import styles from './DeleteModalArea.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import canvasState from '@store/canvasState/canvasState';
import devicesState from '@store/devicesState/devicesState';
import presetState from '@store/presetState/presetState';
import translationState from '@store/translationState/translationState';
import connectState from '@store/connectState/connectState';
import { apiDelete } from '@api/api';
import ConfirmButtons from '@helpers/components/ConfirmButtons/ConfirmButtons';

const DeleteModalArea = observer(() => {
	const {saveDataTest, currentPolygonNum, setCurrentPolygonNum, setIsDeletePolygon} = canvasState;
	const {selectDeviceId} = devicesState;
	const {selectPresetId} = presetState;
	const { setOpenModal } = translationState;
	const { getEventsLink } = connectState;
	
	const [areaName, setAreaName] = useState('');
	const [isDisabledButton, setDisabledButton] = useState(false);

	const polygons = useMemo(() => {
		if(!selectDeviceId || !selectPresetId) return [];
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		return saveDataTest?.[selectDeviceId]?.[selectPresetId] ?? [];
	}, [saveDataTest, selectDeviceId, selectPresetId]);

	const selectPolygon = polygons[currentPolygonNum];

	useEffect(() => {
		const timeout = setTimeout(() => {
			const isDisabled = areaName === selectPolygon.getName();

			setDisabledButton(isDisabled);
		}, 500);

		return () => clearTimeout(timeout);
	}, [areaName]);

	const deletePolygon = () => {
		const url = `${getEventsLink}${selectDeviceId}/deletezone/${selectPolygon.id}/`;

		apiDelete(url, {}).then(({ data, error }) => {
			console.log(data);
			console.log(error);
		});

		setOpenModal(false);
		setCurrentPolygonNum(-1);
		setIsDeletePolygon();
	};

	return (
		<div className={styles.modalContainer}>
			Удаление зоны {selectPolygon.getName()}

			<div className={styles.search}>
				<input 
					className={styles.searchInput} 
					value={areaName} 
					placeholder='Введите название'
					onChange={(e) => setAreaName(e.target.value)} 
				/>
			</div>

			<ConfirmButtons
				nameOk='Удалить'
				isDisabled={!isDisabledButton}
				handleOk={deletePolygon}
				handleCancel={() => setOpenModal(false)}
			/>
		</div>
	);
});

export default DeleteModalArea;
