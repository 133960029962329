import { observer } from 'mobx-react-lite';
import { CheckLoading } from '@components/CheckLoading/CheckLoading';
import { TranslationPageProps as Props } from '../Pages.interface';

import TranslationMain from '@components/Translation/TranslationMain';
import HelmetTags from '@helpers/components/HelmetTags/HelmetTags';
import { useEffect } from 'react';
import { checkPageReady } from '@helpers/functions/loading/translationLoading';

const TranslationPage: React.FC<Props> = observer(({ metaTags }) => {
	useEffect(() => {
		const timeout = setTimeout(() => {
			checkPageReady('isPlaces', true);
			checkPageReady('isDevices', true);
		}, 300);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<CheckLoading page='translation'>
			<HelmetTags metaTags={metaTags} />

			<TranslationMain />
		</CheckLoading>
	);
});

export default TranslationPage;
