import { ClickPosition } from '@components/Translation/ViewBox/ViewBox.interface';
import { Preset } from '@store/presetState/interfaces';
import { TypeCreateNewPreset, TypeSelectArrow } from '@store/translationState/interfaces';
import presetState from '@store/presetState/presetState';

export const moveCamera = (selectComandArrow: TypeSelectArrow, socket: WebSocket | null) => {
	if (socket === null || socket.readyState !== WebSocket.OPEN) return;

	const messageSocket = {
		type: 'ptz_command',
		message: {
			command: 'MOVE',
			data: {
				pos: selectComandArrow,
			},
		},
	};
	console.log(messageSocket);

	socket.send(JSON.stringify(messageSocket));
};

export const pollUpdate = (selectComandArrow: TypeSelectArrow, socket: WebSocket | null) => {
	if (socket === null || socket.readyState !== WebSocket.OPEN) return;

	const messageSocket = {
		type: 'ptz_command',
		message: {
			command: 'POLL',
			data: {
				pos: selectComandArrow,
			},
		},
	};
	console.log(messageSocket);

	socket.send(JSON.stringify(messageSocket));
};

export const moveAboutPoint = (newPoint: ClickPosition, socket: WebSocket | null) => {
	if (!newPoint.x || !newPoint.y || socket === null || socket.readyState !== WebSocket.OPEN) return;

	const messageSocket = {
		type: 'move_by_point',
		message: {
			command: 'MOVEPTZPOINT',
			data: {
				x: newPoint.x,
				y: newPoint.y,
			},
		},
	};
	console.log(messageSocket);

	socket.send(JSON.stringify(messageSocket));
};

export const callPresetCommand = (currNumber: Preset['number'], socket: WebSocket | null) => {
	if (socket === null || socket.readyState !== WebSocket.OPEN) return;

	const messageSocket = {
		type: 'call_preset',
		message: {
			command: 'CALLPRESET',
			data: {
				preset_number: currNumber,
			},
		},
	};
	console.log(messageSocket);

	socket.send(JSON.stringify(messageSocket));
};

export const createPresetCommand = (newPreset: TypeCreateNewPreset, socket: WebSocket | null) => {
	if (socket === null || socket.readyState !== WebSocket.OPEN) return;

	const messageSocket = {
		type: 'create_preset',
		message: {
			command: 'CREATEPRESET',
			data: newPreset,
		},
	};
	console.log(messageSocket);

	socket.send(JSON.stringify(messageSocket));
};

export const deletePresetCommand = (deletePreset: number, socket: WebSocket | null) => {
	if (socket === null || socket.readyState !== WebSocket.OPEN) return;

	const messageSocket = {
		type: 'delete_preset',
		message: {
			command: 'DELETEPRESET',
			data: {
				preset_number: deletePreset,
			},
		},
	};
	console.log(messageSocket);

	socket.send(JSON.stringify(messageSocket));
};

export const patrolUpdateCommand = (patrolCommandWs: string, socket: WebSocket | null) => {
	if (socket === null || socket.readyState !== WebSocket.OPEN) return;

	const messageSocket = {
		type: 'send_to_camerabackend',
		message: {
			command: 'PATROLUPDATE',
			data: {
				new_patrol_type: patrolCommandWs,
			},
		},
	};
	console.log(messageSocket);

	socket.send(JSON.stringify(messageSocket));

	presetState.setPatrolCommandWs('');
};

export const getActivePreset = (socket: WebSocket | null) => {
	if (socket === null || socket.readyState !== WebSocket.OPEN) return;

	const messageSocket = {
		type: 'send_to_camerabackend',
		message: {
			command: 'GETACTIVEPRESET',
			data: {},
		},
	};
	console.log(messageSocket);

	socket.send(JSON.stringify(messageSocket));
};
